/*--- app routes ---*/
const ROUTE_PATH = {
  root: '/',
  login: '/login',
  dashboard: '/dashboard',
  questionaire: {
    root: '/questionaire',
    questionaire: '/questionaire',
    createquestionaire: '/questionaire/create',
    questionaireDetail: '/questionaire/:id',
    editquestionaire: '/questionaire/:id/edit',
  },
  announcement: {
    root: '/announcement',
    announcement: '/announcement',
    createAnnouncement: '/announcement/create',
    announcementDetail: '/announcement/:id',
    editAnnouncement: '/announcement/:id/edit',
  },
  employer: {
    root: '/employer',
    list: '/employer/employers',
    detail: '/employer/employers/detail/:id',
    package: '/employer/package',
    cvDownload: '/employer/cv-download',
  },
  job: '/job',
  jobDetail: '/job/detail/:id',
  jobEdit: '/job/detail/:id/edit',
  jobSeeker: {
    root: '/job-seeker',
    jobSeeker: '/job-seeker/job-seeker',
    jobApplied: '/job-seeker/job-applied',
    interviews: '/job-seeker/interview',
    offerLetter: '/job-seeker/offter-letter',

    jobSeekerDetail: 'job-seeker/job-seeker/:id',
    jobSeekerProfile: '/job-seeker/job-seeker/:id/profile',
    interviewHistory: '/job-seeker/job-seeker/:id/interview-history',
    applyJobHistory: '/job-seeker/job-seeker/:id/job-apply-history',
    noteHistory: '/job-seeker/job-seeker/:id/note-history',
    jobSeekerUpdate: '/job-seeker/job-seekers-list/:id/edit',
  },
  common: {
    industry: '/common/industry',
    category: '/common/category',
  },
  blogging: {
    category: '/blogging/category',
    post: '/blogging/post',
    createPost: '/blogging/post/create',
    postDetail: '/blogging/post/:id',
    editPost: '/blogging/post/:id/edit',
  },
  // employer:{
  //   employers: '/employer/employers',
  //   package: '/employer/package',
  //   cvDownload: '/employer/cv-download',
  // }

  videoCall: '/interview/video-call/:id',

  learnMore: {
    category: '/learn-more/category',
    video: '/learn-more/video',
    createVideo: '/learn-more/video/create',
    editVideo: '/learn-more/video/:id/edit',
    videoDetail: '/learn-more/video/:id',
  },

  user: {
    profile: '/user-management/profile',
    userList: '/user-management/user-list',
    createUser: '/user-management/user-list/create',
    updateUser: '/user-management/user-list/:id/edit',
  },

  assessmentReport: '/report/assessment',

  configuation: '/configuration',
};

/*--- api routes  ---*/
const ROUTE_API = {
  baseURL: process.env.REACT_APP_API_URL,

  login: '/api/admin/v2/login',
  logout: '/api/auth/v2/logout',

  // Dashboard
  getTotalEmployer: '/api/admin/v1/employer/dashboard',
  getTotalJob: '/api/admin/v1/job/dashboard',
  getTotalJobSeeker: '/api/admin/v2/job-seeker/dashboard',

  // Questionaire
  getQuestionGroupList: '/api/question-group/v1/backoffice/list',
  getQuestionGroupAutoComplete: '/api/question-group/v1/backoffice/autocomplete',
  createQuestionGroup: '/api/question-group/v1/create',
  getQuestionGroupDetail: '/api/question-group/v1/detail/',
  updateQuestionGroup: '/api/question-group/v1/:id',
  enableQuestionGroup: '/api/question-group/v1/:id/enable',
  disableQuestionGroup: '/api/question-group/v1/:id/disable',
  deleteQuestionGroup: '/api/question-group/v1/:id',
  createQuestion: '/api/question-group/v1/add-question',
  updateQuestion: '/api/question-group/v1/update-question',
  removeQuestion: '/api/question-group/v1/remove-question/',
  reorderQuestions: '/api/question-group/v1/reorder-questions',
  backofficeJobQuestionnaire: '/api/question-answer-group/v1/backoffice/list',
  questionAnswerGroup: '/api/question-answer-group/v1/detail/:id',
  updateAnswerStatus: '/api/question-answer/v1/status',
  updateAnswerGroupStatus: '/api/question-answer-group/v1/status',

  // Announcement
  getAnnouncementList: '/api/announcement/v1/list',
  createAnnouncement: '/api/announcement/v1/create',
  getAnnouncementDetail: '/api/announcement/v1/:id',
  updateAnnouncement: '/api/announcement/v2/:id',
  activateAnnouncement: '/api/announcement/v1/:id/active',
  deleteAnnouncement: '/api/announcement/v1/:id',

  // Common
  categoryList: '/api/category/v2/list',
  industryList: '/api/industry/v2/list',
  industryCreate: '/api/backoffice/industry/v2/create',
  industryUpdate: '/api/backoffice/industry/v2/update',
  industryDelete: '/api/backoffice/industry/v2/remove/:id',
  categoryCreate: '/api/backoffice/category/v2/create',
  categoryUpdate: '/api/backoffice/category/v2/update',
  categoryDelete: '/api/backoffice/category/v2/remove/:id',
  benefitList: '/api/benefit/v2/list',
  totalEmployee: '/api/employee-total/v2/list',
  uploadFile: '/api/file/v2/upload',

  // Employer
  employerList: '/api/backoffice/employer/v2/list',
  exportEmployerList: '/api/backoffice/employer/v2/generate',
  employerTelegramList: '/api/backoffice/employer/v1/list/telegram',
  employerDetail: '/api/backoffice/employer/v2/detail/:id',
  editEmployer: '/api/backoffice/employer/v2/company-profile/update',
  unlinkTelegram: '/api/backoffice/employer/v2/unlink-telegram',
  activateEmployer: '/api/backoffice/employer/v2/activate',
  deactivateEmployer: '/api/backoffice/employer/v2/deactivate',

  // Package
  packageList: '/api/backoffice/service-package/v2/list',
  exportPackageList: '/api/backoffice/service-package/v2/generate',
  deletePackage: '/api/backoffice/service-package/v2/delete/:id',
  purchasePackgae: 'api/backoffice/service-package/v2/status/update',

  // CV Downloaded
  cvDownloadedList: '/api/backoffice/employer/v2/cv-download/list',

  // Job
  getJobList: '/api/backoffice/job/v2/list',
  getJobDetail: 'api/backoffice/job/v2/detail/',
  updateJob: '/api/backoffice/job/v2/update',
  assignQuestionGroup: '/api/backoffice/job/v2/assign-question',
  unassignQuestionGroup: '/api/backoffice/job/v2/unassign-question',
  uploadImage: '/api/file/v2/upload-banner',
  exportJobList: '/api/backoffice/job/v2/generate',
  exportJobDetail: '/api/backoffice/job/v2/generateDetail/',

  // Job Seekers
  jobSeekerList: '/api/backoffice/job-seeker/v2/list',
  exportJobSeekerList: '/api/backoffice/profile/v2/generate',
  jobSeekerDetail: '/api/backoffice/profile/v2/detail/:id',
  downloadResume: 'api/backoffice/profile/v2/download/:id',
  getListInterviewHistory: '/api/backoffice/appointment/v2/profile/:id',
  getListApplyJobHistory: '/api/backoffice/apply-job/v2/profile/:id',
  getNoteHistory: '/api/backoffice/note/v2/list',
  retryProcessAiraCV: '/api/backoffice/job-seeker/v2/process-cv',

  createEducation: '/api/backoffice/profile/v2/education/create',
  createTrainingSkill: '/api/backoffice/profile/v2/training-skill/create',
  createExperience: '/api/backoffice/profile/v2/experience/create',
  createLanguage: '/api/backoffice/profile/v2/language/create',
  createInterviewJob: 'api/backoffice/appointment/v2/create',
  createApplyJob: 'api/backoffice/apply-job/v2/custom/create',
  createNote: 'api/backoffice/note/v2/create',

  updateJobSeeker: '/api/backoffice/profile/v2/update',
  updateEducation: '/api/backoffice/profile/v2/education/update',
  updateTrainingSkill: '/api/backoffice/profile/v2/training-skill/update',
  updateExperience: '/api/backoffice/profile/v2/experience/update',
  updateLanguage: '/api/backoffice/profile/v2/language/update',
  updateComputerSkill: 'api/backoffice/profile/v2/computer-skill/update',
  updateAboutMe: '/api/backoffice/profile/v2/about/update',
  updateInterviewProfile: '/api/backoffice/appointment/v2/update',
  updateNoteHistroy: '/api/backoffice/note/v2/update',

  deleteEducation: '/api/backoffice/profile/v2/education/:id',
  deleteTrainingSkill: '/api/backoffice/profile/v2/training-skill/:id',
  deleteExperience: '/api/backoffice/profile/v2/experience/:id',
  deleteLanguage: '/api/backoffice/profile/v2/language/:id',
  deleteNoteHistory: '/api/backoffice/note/v2/delete/:id',

  // Job Applied
  jobAppliedList: '/api/backoffice/apply-job/v2/list',
  exportAppliedJobList: '/api/backoffice/apply-job/v2/generate',
  updateApplyJobStatus: '/api/backoffice/apply-job/v2/status/update',

  // Interviews
  interviewList: '/api/backoffice/appointment/v2/list',
  createInterview: '/api/appointment/v2/employer/create',
  updateInterview: '/api/backoffice/appointment/v2/update',
  interviewDetail: '/api/appointment/v2/:id',
  startInterview: '/api/appointment/v2/:id/start',
  endInterview: '/api/appointment/v2/:id/complete',

  // Offer Letter
  offerLetterList: '/api/offer-letter/v2/list',
  createOfferLetter: '/api/offer-letter/v2/create',
  exportOfferLetterList: '/api/offer-letter/v2/generate',

  //Blogging
  blogCategorylist: '/api/blog-category/v2/list',
  blogCategorycreate: '/api/blog-category/v2/create',
  blogCategoryudpate: '/api/blog-category/v2/:id',
  blogCategoryachieve: '/api/blog-category/v2/:id/archive',
  blogCategoryunachieve: '/api/blog-category/v2/:id/unarchive',
  blogCategoryAutoComplete: '/api/blog-category/v2/autocomplete',

  blogPostlist: '/api/blog-post/v2/backoffice/list',
  blogPostCreate: '/api/blog-post/v2/create',
  blogUploadImage: '/api/file/v2/upload',
  blogPostDetail: '/api/blog-post/v2/:id',
  blogUpdate: '/api/blog-post/v2/:id',
  blogDeletet: '/api/blog-post/v2/:id/delete',
  blogStatusPublish: '/api/blog-post/v2/:id/publish',
  blogStatusDraft: '/api/blog-post/v2/:id/draft',

  // Learn More
  categoryListVideo: '/api/video-category/v2/backoffice/list',
  categoryCreateVideo: '/api/video-category/v2/create',
  categoryStatus: '/api/video-category/v2/:id/status',
  categoryUpdateVideo: '/api/video-category/v2/:id',

  videoList: '/api/video/v2/backoffice/list',
  vidoeDraft: '/api/video/v2/:id/draft',
  videoPublished: '/api/video/v2/:id/publish',
  videoCreate: '/api/video/v2/create',
  videoUpdate: '/api/video/v2/:id',
  videoDetail: '/api/video/v2/detail/:id',

  //User Management
  userList: '/api/admin/v2/list',
  userCreate: '/api/admin/v2/create',
  userEdit: '/api/admin/v2/profile/:id',

  profile: '/api/admin/v2/me',
  changePassword: '/api/auth/v2/change-password',
  updateProfile: '/api/admin/v2/me',

  // Configuration
  configUpdate: '/api/configuration/v2/EmployerSetting',
  telgramBotConfig: '/api/configuration/v2/TelegramBot',

  // DISC
  listAssessment: '/api/disc/v2/list',
  createAssessment: '/api/disc/v2/create',
  getAssessmentResult: '/api/disc/v2/:type/results/:id',
};

const PHC_ROUTE_API = {
  baseURL: 'https://staging.phumcareermatching.com',

  getAssessmentUserReport: '/api/agency/report-member-do-assessment',

  getSubscribePlan: '/api/agency/subscribe-plan-info',

  requestKey: '/api/agency/:type',

  requestSilverKey: '/api/agency/request-key-silver',
};

export { ROUTE_API, ROUTE_PATH, PHC_ROUTE_API };
